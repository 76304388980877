
export const layoutDataType = {
    
    LOAD_ALL_SIDER_DATA_START:"LOAD_ALL_SIDER_DATA_START",
    
    LOAD_ALL_SIDER_DATA_SUCCESS:"LOAD_ALL_SIDER_DATA_SUCCESS",
    
    LOAD_ALL_SIDER_DATA_ERROR:"LOAD_ALL_SIDER_DATA_ERROR",
    
    
}