const LayoutEN = {
    layout: {
        dashboard: 'Dashboard',
        setting: 'Setting',
        hrm: 'HRM',
        system: 'System',
        workplace: 'Workplace'
    }
}

export default LayoutEN
