export const loginTypes = {
    REQUEST: 'LOGIN_REQUEST',
    SUCCESS: 'LOGIN_SUCCESS',
    FAILURE: 'LOGIN_FAILURE',

    LOGOUT: 'LOGOUT',

    GET_TOKEN_REQUEST: 'GET_TOKEN_REQUEST',
    GET_TOKEN_SUCCESS: 'GET_TOKEN_SUCCESS',
    GET_TOKEN_ERROR: 'GET_TOKEN_ERROR',

    UPDATE_USERNAME_PASSWORD: 'UPDATE_USERNAME_PASSWORD',

    LOADING_LOGIN: 'LOADING_LOGIN',
    CLEAR_LOGIN_DATA: 'CLEAR_LOGIN_DATA'
}
